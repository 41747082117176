<template>
  <el-form :model="contractItemForm" :rules="rules" ref="form" class="contractItemForm">
    <el-row class="item_row">
      <el-col :span="2" class="label_col">
        <span class="label">报价项目:</span>
      </el-col>
      <el-col :span="3">
        <el-select v-model="base_item" placeholder="请选择" @change="getBaseItem" style="display:inline">
          <el-option label="租金" value="租金"></el-option>
          <el-option label="维修押金" value="维修押金"></el-option>
          <el-option label="吊装费" value="吊装费"></el-option>
          <el-option label="运费" value="运费"></el-option>
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-input v-model="additional_text" placeholder="此处可以输入附加说明（如增量...）" @input="getAdditionalText"> </el-input>
      </el-col>
      <el-col :span="3" class="label_col">
        <span style="color:blue">报价项目预览:</span>
      </el-col>
      <el-col :span="9">
        <el-input v-model="contractItemForm.item" readonly> </el-input>
      </el-col>
    </el-row>
    <div style="height:30px"></div>
    <el-row class="amount_row">
      <el-col :span="2" class="label_col">
        <span class="label">报价金额:</span>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="item_amount">
          <el-input type="number" step="0.01" placeholder="0要写成0.00" v-model="contractItemForm.item_amount" style="width:300px"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div style="padding:15px; ">
      <span class="label"> 备注信息:</span>
    </div>
    <el-row class="remark_row" v-if="isCailiao_guige_show">
      <el-col :span="2" class="label_col">
        <span class="label">材料:</span>
      </el-col>
      <el-col :span="4">
        <el-select v-model="cailiao" placeholder="请选择" @change="getRemarkCailiao">
          <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2" class="label_col">
        <span>材料规格</span>
      </el-col>
      <el-col :span="4">
        <el-select v-model="cailiaoguige" placeholder="请选择" @change="getRemarkGuige">
          <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <div style="height:10px"></div>

    <div class="border_div">
      <el-row id="remark_zujin" v-if="remark_tools === 1">
        <el-col :span="2" class="label_col">
          <span>单价/数量</span>
        </el-col>
        <el-col :span="6">
          <el-input v-model="zj_dj" @input="getRemark_zj_dj">
            <template slot="append"
              >元/片/天</template
            >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="zj_day" @input="getRemark_zj_day">
            <template slot="append"
              >天</template
            >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="zj_sl" @input="getRemark_zj_sl">
            <template slot="append"
              >片</template
            >
          </el-input>
        </el-col>
      </el-row>

      <el-row id="remark_weixiuyajin" v-if="remark_tools === 2">
        <el-col :span="2" class="label_col">
          <span>单价/数量</span>
        </el-col>
        <el-col :span="6">
          <el-input v-model="wxyj_dj" @input="getRemark_wxyj_dj">
            <template slot="append"
              >元/片</template
            >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="wxyj_sl" @input="getRemark_wxyj_sl">
            <template slot="append"
              >片</template
            >
          </el-input>
        </el-col>
      </el-row>

      <el-row id="remark_diaozhuangfei" v-if="remark_tools === 3">
        <el-col :span="2" class="label_col">
          <span>单价/数量</span>
        </el-col>
        <el-col :span="6">
          <el-input v-model="dzf_dj" @input="getRemark_dzf_dj">
            <template slot="append"
              >元/吨/次</template
            >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="dzf_cs" @input="getRemark_dzf_cs">
            <template slot="append"
              >次</template
            >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="dzf_ds" @input="getRemark_dzf_ds">
            <template slot="append"
              >吨</template
            >
          </el-input>
        </el-col>
      </el-row>

      <div style="height:20px"></div>
      <el-row id="other_row">
        <el-col :span="4" class="label_col">
          <span>其他备注信息</span>
        </el-col>
        <el-col :span="20">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="remark_others" @input="getRemark_others"> </el-input>
        </el-col>
      </el-row>

      <el-row id="remark_preview">
        <el-col :span="4" class="label_col">
          <span style="color:blue">备注预览</span>
        </el-col>
        <el-col :span="20">
          <el-form-item prop="remark">
            <el-input type="textarea" :rows="3" v-model="contractItemForm.remark" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    contractItemForm: Object
  },
  data() {
    return {
      isValidationOk: false,
      base_item: '',
      additional_text: '',
      cailiao: '',
      cailiaoguige: '',
      cailiaos_options: [],
      cailiaoguiges_options: [],

      zj_dj: '',
      zj_day: '',
      zj_sl: '',
      wxyj_dj: '',
      wxyj_sl: '',
      dzf_dj: '',
      dzf_cs: '',
      dzf_ds: '',
      remark_others: '',

      rules: {
        item: [{ required: true, message: '请输入合同分项名称', trigger: 'blur' }],
        item_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      }
    }
  },
  computed: {
    remark_tools() {
      if (this.base_item === '租金') return 1
      else if (this.base_item === '维修押金') return 2
      else if (this.base_item === '吊装费' || this.base_item === '运费') return 3
      else return 0
    },
    isCailiao_guige_show() {
      if (this.base_item === '吊装费' || this.base_item === '运费') return false
      else return true
    }
  },

  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
          // this.resetForm()
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    },
    getAdditionalText(val) {
      this.contractItemForm.item = this.base_item + val
    },
    getBaseItem(val) {
      this.contractItemForm.item = val + this.additional_text
      // this.contractItemForm.item_amount = ''
      this.contractItemForm.remark = ''
      this.cailiao = ''
      this.cailiaoguige = ''

      this.additional_text = ''

      this.zj_dj = ''
      this.zj_day = ''
      this.zj_sl = ''
      this.wxyj_dj = ''
      this.wxyj_sl = ''
      this.dzf_dj = ''
      this.dzf_cs = ''
      this.dzf_ds = ''
      this.remark_others = ''
    },
    getCailiao(val) {
      this.contractItemForm.remark = val
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = String(element.name)
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = String(element.name)
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },

    getRemarkCailiao(val) {
      if (this.remark_tools === 1) {
        this.contractItemForm.remark =
          val + this.cailiaoguige + ', ' + this.zj_dj + '元/片/天, ' + this.zj_day + '天, ' + this.zj_sl + '片。 ' + this.remark_others
      } else if (this.remark_tools === 2) {
        this.contractItemForm.remark = val + this.cailiaoguige + ', ' + this.wxyj_dj + '元/片, ' + this.wxyj_sl + '片, ' + this.remark_others
      } else if (this.remark_tools === 3) {
        this.contractItemForm.remark =
          val + this.cailiaoguige + ', ' + this.dzf_dj + '元/吨/次, ' + this.dzf_cs + '次, ' + this.dzf_ds + '吨, ' + this.remark_others
      } else {
        this.contractItemForm.remark = val + this.cailiaoguige + ', ' + this.remark_others
      }
    },
    getRemarkGuige(val) {
      if (this.remark_tools === 1) {
        this.contractItemForm.remark = this.cailiao + val + ', ' + this.zj_dj + '元/片/天, ' + this.zj_day + '天, ' + this.zj_sl + '片。 ' + this.remark_others
      } else if (this.remark_tools === 2) {
        this.contractItemForm.remark = this.cailiao + val + ', ' + this.wxyj_dj + '元/片, ' + this.wxyj_sl + '片, ' + this.remark_others
      } else if (this.remark_tools === 3) {
        this.contractItemForm.remark = this.cailiao + val + ', ' + this.dzf_dj + '元/吨/次, ' + this.dzf_cs + '次, ' + this.dzf_ds + '吨, ' + this.remark_others
      } else {
        this.contractItemForm.remark = this.cailiao + val + '  ' + this.remark_others
      }
    },

    getRemark_zj_dj(val) {
      this.contractItemForm.remark =
        this.cailiao + this.cailiaoguige + ', ' + val + '元/片/天, ' + this.zj_day + '天, ' + this.zj_sl + '片。 ' + this.remark_others
    },
    getRemark_zj_day(val) {
      this.contractItemForm.remark =
        this.cailiao + this.cailiaoguige + ', ' + this.zj_dj + '元/片/天, ' + val + '天, ' + this.zj_sl + '片。 ' + this.remark_others
    },
    getRemark_zj_sl(val) {
      this.contractItemForm.remark =
        this.cailiao + this.cailiaoguige + ', ' + this.zj_dj + '元/片/天, ' + this.zj_day + '天, ' + val + '片。 ' + this.remark_others
    },

    getRemark_wxyj_dj(val) {
      this.contractItemForm.remark = this.cailiao + this.cailiaoguige + ', ' + val + '元/片, ' + this.wxyj_sl + '片, ' + this.remark_others
    },
    getRemark_wxyj_sl(val) {
      this.contractItemForm.remark = this.cailiao + this.cailiaoguige + ', ' + this.wxyj_dj + '元/片, ' + val + '片, ' + this.remark_others
    },

    getRemark_dzf_dj(val) {
      this.contractItemForm.remark = val + '元/吨/次, ' + this.dzf_cs + '次, ' + this.dzf_ds + '吨, ' + this.remark_others
    },
    getRemark_dzf_cs(val) {
      this.contractItemForm.remark = this.dzf_dj + '元/吨/次, ' + val + '次, ' + this.dzf_ds + '吨, ' + this.remark_others
    },
    getRemark_dzf_ds(val) {
      this.contractItemForm.remark = this.dzf_dj + '元/吨/次, ' + this.dzf_cs + '次, ' + val + '吨, ' + this.remark_others
    },
    getRemark_others(val) {
      if (this.remark_tools === 1) {
        this.contractItemForm.remark = this.cailiao + this.cailiaoguige + ',' + this.zj_dj + '元/片/天, ' + this.zj_day + '天, ' + this.zj_sl + '片。 ' + val
      } else if (this.remark_tools === 2) {
        this.contractItemForm.remark = this.cailiao + this.cailiaoguige + ', ' + this.wxyj_dj + '元/片, ' + this.wxyj_sl + '片, ' + val
      } else if (this.remark_tools === 3) {
        this.contractItemForm.remark = this.dzf_dj + '元/吨/次, ' + this.dzf_cs + '次, ' + this.dzf_ds + '吨, ' + val
      } else {
        this.contractItemForm.remark = this.cailiao + this.cailiaoguige + ', ' + val
      }
    }
  },
  created() {
    this.getCailiaos()
    this.getCailiaoguiges()
  }
}
</script>

<style></style>
